<template>
  <div class="p-4 pb-8 bg-white rounded-lg custom-container-height">
    <div class="mb-7 mx-4 mt-6 d-flex align-center">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="tableProperties"
      ></datatable-detail>
    </div>
    <div class="mx-7">
      <v-tabs show-arrows align-with-title>
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          active-class="brown lighten-5 rounded"
          v-for="item in tabItems"
          :key="item"
        >
          {{ item }}
        </v-tab>
        <v-tab-item class="mt-5">
          <ReceivingConfiguration />
        </v-tab-item>
        <v-tab-item class="mt-5">
          <PutawayConfiguration />
        </v-tab-item>
        <v-tab-item class="mt-5">
          <OutboundConfiguration />
        </v-tab-item>
        <v-tab-item class="mt-5">
          <InventoryConfiguration />
        </v-tab-item>
        <v-tab-item class="mt-5">
          <PrintConfiguration />
        </v-tab-item>
        <v-tab-item class="mt-5">
          <ReturnConfiguration />
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ReceivingConfiguration from "./ReceivingConfiguration.vue";
import PutawayConfiguration from "./PutawayConfiguration.vue";
import OutboundConfiguration from "./OutboundConfiguration.vue";
import InventoryConfiguration from "./InventoryConfiguration.vue";
import PrintConfiguration from "./print/PrintConfiguration.vue";
import ReturnConfiguration from "./ReturnConfiguration.vue";

export default {
  name: "ConfigurationSettings",
  components: {
    DatatableDetail,
    ReceivingConfiguration,
    PutawayConfiguration,
    OutboundConfiguration,
    InventoryConfiguration,
    PrintConfiguration,
    ReturnConfiguration,
  },
  data() {
    return {
      tabItems: [
        "Receiving",
        "Putaway",
        "Outbound",
        "Inventory",
        "Print",
        "Returns",
      ],
      tableProperties: {
        description: "Manage the main configuration",
        title: "Manage Configuration",
      },
    };
  },
};
</script>

<!-- // refund trigger status -->
